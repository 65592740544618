import { FC } from "react";

import { SearchCriteria } from "../../../../types/common/search";

import { UWI_TAB } from "../../../../constants/constants";
import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";

import useMapDrawStore from "../../../../store/map/draw/mapDrawStore";
import usePanelsStore from "../../../../store/panels/panelsStore";
import useQueryBuilderStore from "../../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../../store/useStore";

import useRecordType from "../../../../customHooks/common/useRecordType";
import useSearchPanelActions from "../../../../customHooks/search/useSearchPanelActions";

import { containsAtLeastOne } from "../../../../utils/helper2";
import { getPolygonType } from "../../../../utils/map/draw/polygon";

import ActionButtons from "../common/ActionButtons";
import ExpectedCount from "../common/ExpectedCount";
import TabPanel from "../common/TabPanel";
import PolygonSearch from "./polygons/PolygonSearch";
import QueryBuilder from "./queryBuilder/QueryBuilder";
import QBEditorPanel from "./queryBuilder/qbEditor/QBEditorPanel";

interface Props {
  hidden: boolean;
}

const AttributesTabPanel: FC<Props> = ({ hidden }) => {
  const {
    searchCallback,
    resetCallback,
    resetAttribsPolygons,
    hasLoadedAttribSavedSearch,
    hasLoadedUWISavedSearch,
  } = useSearchPanelActions();

  const { isCurrentBoundRecordTypeOnly } = useRecordType();

  const lastSearchedBy = useStore((state) => state.lastSearchedBy);
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);

  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);

  const updateIsFromUploadedUWI = useUWISearchStore(
    (state) => state.updateIsFromUploadedUWI
  );

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const isUploadingShapeFile = useMapDrawStore(
    (state) => state.isUploadingShapeFile
  );

  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const resetUWIStates = useUWISearchStore((state) => state.resetUWIStates);

  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);

  const isSearchDisabled = (isReset: boolean) => {
    let hasValue = false;
    if (containsAtLeastOne(drawnPolygons) || shapeId) hasValue = true;

    if (!isReset && isCurrentBoundRecordTypeOnly && !hasValue) return true;

    const attributeValues = Object.values(currentQBInfo);
    let hasErrorValue = false;

    for (let i = 0; i < attributeValues.length; i++) {
      const { hasError, values } = attributeValues[i];

      // check if any attribute has error
      if (hasError) {
        hasErrorValue = true;
        break;
      }

      // check if any attribute has value and operationType
      if (!hasValue && containsAtLeastOne(values)) {
        hasValue = true;
      }
    }

    return hasErrorValue || !hasValue || isUploadingShapeFile;
  };

  const handleSearchAttribute = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      currentBounds,
      searchedUWIs: [],
      shapeId,
      fileId: "",
    };
    searchCallback(newSearchCriteria, !hasLoadedUWISavedSearch);
    closeAttributesPanel();
    updateIsFromUploadedUWI(false);
    updateLastSearchedBy(SEARCH_TYPES.ATTRIBUTE_SEARCH);
    resetUWIStates();
  };

  const handleResetAttribute = () => {
    resetAttribsPolygons();
    resetCallback(hasLoadedAttribSavedSearch);
  };

  const closeAttributesPanel = () => {
    if (isOpenQBEditorPanel) {
      toggleIsOpenQBEditorPanel(false);
    }
  };

  return (
    <TabPanel hidden={hidden} name="attributes">
      <div className="search-panel-body">
        <QueryBuilder />
        <div className="padded">
          <PolygonSearch />
        </div>
      </div>
      <div className="search-panel-footer">
        <div className="expected-well-counts">
          <ExpectedCount />
        </div>

        <ActionButtons
          isResetDisabled={isSearchDisabled(true)}
          isSaveDisabled={isSearchDisabled(false)}
          isSearchDisabled={isSearchDisabled(false)}
          isShowPrompt={
            lastSearchedBy === SEARCH_TYPES.UWI_SEARCH ||
            lastSearchedBy === SEARCH_TYPES.UWI_FILE_SEARCH ||
            lastSearchedBy === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH
          }
          hasLoadedSavedSearch={hasLoadedAttribSavedSearch}
          handleResetClick={handleResetAttribute}
          onClickSaveCallback={resetUWIStates}
          handleSearchClick={handleSearchAttribute}
          handleCancelClick={() => updateActiveSearchPanelTab(UWI_TAB)}
        />
      </div>
      <QBEditorPanel />
    </TabPanel>
  );
};

export default AttributesTabPanel;
