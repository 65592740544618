import {
  DEFAULT_ATTRIBUTE_COLOR,
  DEFAULT_TOP_COLORS,
} from "../../constants/map/mapSettings";

import { floatValidation } from "../panel/wellPanel/fieldValidation";
import { truncateString } from "../stringUtils";

const legendNameAcronymRegEx = /\([^()]*\)$/gm;

const formatName = (
  fullName: string,
  rawName: string,
  truncationLength: number,
  matchedAcronym: RegExpMatchArray | null
) => {
  let formattedName = "";
  if (fullName.length > truncationLength) {
    formattedName = truncateString(fullName, truncationLength).concat(
      matchedAcronym?.[0] ?? ""
    );
  } else {
    formattedName = rawName;
  }

  return formattedName;
};

export const legendNameFormatter = (name: string, truncationLength: number) => {
  let formattedName = "";
  const matchedAcronym = name.match(legendNameAcronymRegEx);

  if (matchedAcronym) {
    const fullName = name.replace(matchedAcronym[0], "");
    formattedName = formatName(
      fullName,
      name,
      truncationLength,
      matchedAcronym
    );
  } else {
    formattedName = truncateString(name, truncationLength);
  }

  return formattedName;
};

const formatLateralLenghtUnit = (
  fieldHasLateralLength: boolean,
  chartSettingShowLateralLength: boolean,
  fieldUnit: string
) => {
  let formattedLateralLenghtUnit = fieldUnit;

  if (fieldHasLateralLength && chartSettingShowLateralLength) {
    formattedLateralLenghtUnit = `${fieldUnit} per ft`;
  }

  return formattedLateralLenghtUnit;
};

const formatDailyUnit = (
  fieldHasDaily: boolean,
  chartSettingShowDaily: boolean,
  fieldUnit: string
) => {
  let formattedDailyUnit = fieldUnit;
  if (fieldHasDaily && chartSettingShowDaily) {
    formattedDailyUnit = `${fieldUnit}/d`;
  }
  return formattedDailyUnit;
};

export const formatUnit = (
  fieldHasDaily: boolean,
  chartSettingShowDaily: boolean,
  fieldHasLateralLength: boolean,
  chartSettingShowLateralLength: boolean,
  fieldUnit: string
) => {
  let formattedUnit = "";

  if (fieldUnit) {
    const formattedDailyUnit = formatDailyUnit(
      fieldHasDaily,
      chartSettingShowDaily,
      fieldUnit
    );
    const formattedLateralLengthUnit = formatLateralLenghtUnit(
      fieldHasLateralLength,
      chartSettingShowLateralLength,
      formattedDailyUnit
    );

    formattedUnit = formattedLateralLengthUnit;
  } else {
    formattedUnit = fieldUnit;
  }

  return formattedUnit;
};

export const formatTraceName = (
  fieldHasDaily: boolean,
  chartSettingShowDaily: boolean,
  fieldDailyDisplayName: string,
  fieldDefaultDisplayName: string,
  isForForecastData: boolean
) => {
  let formattedTraceName = "";

  if (fieldHasDaily && chartSettingShowDaily) {
    formattedTraceName = fieldDailyDisplayName;
  } else {
    formattedTraceName = fieldDefaultDisplayName;
  }

  if (isForForecastData) {
    formattedTraceName += " Forecast";
  }

  return formattedTraceName;
};

export const formattedTraceText = (
  formattedTraceName: string,
  formattedUnit: string,
  hasUnit: boolean
) => {
  let formattedTraceText = formattedTraceName;

  if (hasUnit) {
    formattedTraceText += `, ${formattedUnit}`;
  }

  return formattedTraceText;
};

export const formatColorsToRGB = (color: number[]) => {
  return `rgb(${color[0]},${color[1]},${color[2]})`;
};

export const formatDefaultTopColorsToRGB = () => {
  const colors: string[] = [];

  DEFAULT_TOP_COLORS.forEach((color) => {
    colors.push(formatColorsToRGB(color));
  });

  return colors;
};

export const formatDefaultColorToRGB = () => {
  return formatColorsToRGB(DEFAULT_ATTRIBUTE_COLOR);
};

export const formatHoverValue = (fieldValue: number) => {
  if (fieldValue < 10) {
    return floatValidation(fieldValue, 3, true);
  } else {
    return floatValidation(fieldValue, 0, true);
  }
};
