import { useCallback } from "react";

import {
  attributesInBbl,
  attributesInBblPerFt,
  attributesInBblPerMmcf,
  attributesInBoe,
  attributesInBoePerFt,
  attributesInCfPerBbl,
  attributesInFt,
  attributesInGallon,
  attributesInGramsPerCubicCentimeter,
  attributesInMcf,
  attributesInMcfPerFt,
  attributesInOhmm,
  attributesInPounds,
  attributesInPoundsPerFt,
  attributesInUsPerFt,
} from "../../constants/common/atttributeUnits";

import useUnitOfMeasure from "./useUnitOfMeasure";

const useAttributeUnit = () => {
  const { isMetricOnSearch } = useUnitOfMeasure();

  const convertGallonToLiter = (isMetric: boolean) => {
    return isMetric ? "liter" : "gal";
  };

  const convertGramsPerCubicMeter = "g/cm\u00B3";

  const convertBblToCubicMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3" : "bbl";
  };

  const convertBblPerFtToCubicMeterPerMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3/m" : "bbl/ft";
  };

  const convertFtToMeter = (isMetric: boolean) => {
    return isMetric ? "m" : "ft";
  };

  const convertPoundsToKilograms = (isMetric: boolean) => {
    return isMetric ? "kg" : "lb";
  };

  const convertPoundsPerFtToKilogramPerMeter = (isMetric: boolean) => {
    return isMetric ? "kg/m" : "lb/ft";
  };

  const convertOhmm = "Ohmm";

  const convertUsPerFtToUSPerMeter = (isMetric: boolean) => {
    return isMetric ? "us/m" : "us/ft";
  };

  const convertBoePerFtToCubicMeterPerMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3/m" : "boe/ft";
  };

  const convertBoeToCubicMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3" : "boe";
  };

  const convertMcfToCubicMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3" : "mcf";
  };

  const convertCfPerBblToCubicMeterPerCubicMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3/m\u00B3" : "cf/bbl";
  };

  const convertBblPerMmcfToCubicMeterPerCubicMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3/m\u00B3" : "bbl/mmcf";
  };

  const convertMcfPerFtToCubicMeterPerMeter = (isMetric: boolean) => {
    return isMetric ? "m\u00B3/m" : "mcf/ft";
  };

  const getAttributeUnit = useCallback(
    (attributeKey: string) => {
      const sanitizedKey = attributeKey.toLowerCase();

      if (attributesInGallon.includes(sanitizedKey)) {
        return convertGallonToLiter(isMetricOnSearch);
      } else if (attributesInGramsPerCubicCentimeter.includes(sanitizedKey)) {
        return convertGramsPerCubicMeter;
      } else if (attributesInBbl.includes(sanitizedKey)) {
        return convertBblToCubicMeter(isMetricOnSearch);
      } else if (attributesInBblPerFt.includes(sanitizedKey)) {
        return convertBblPerFtToCubicMeterPerMeter(isMetricOnSearch);
      } else if (attributesInFt.includes(sanitizedKey)) {
        return convertFtToMeter(isMetricOnSearch);
      } else if (attributesInPounds.includes(sanitizedKey)) {
        return convertPoundsToKilograms(isMetricOnSearch);
      } else if (attributesInPoundsPerFt.includes(sanitizedKey)) {
        return convertPoundsPerFtToKilogramPerMeter(isMetricOnSearch);
      } else if (attributesInOhmm.includes(sanitizedKey)) {
        return convertOhmm;
      } else if (attributesInUsPerFt.includes(sanitizedKey)) {
        return convertUsPerFtToUSPerMeter(isMetricOnSearch);
      } else if (attributesInBoePerFt.includes(sanitizedKey)) {
        return convertBoePerFtToCubicMeterPerMeter(isMetricOnSearch);
      } else if (attributesInBoe.includes(sanitizedKey)) {
        return convertBoeToCubicMeter(isMetricOnSearch);
      } else if (attributesInMcf.includes(sanitizedKey)) {
        return convertMcfToCubicMeter(isMetricOnSearch);
      } else if (attributesInCfPerBbl.includes(sanitizedKey)) {
        return convertCfPerBblToCubicMeterPerCubicMeter(isMetricOnSearch);
      } else if (attributesInBblPerMmcf.includes(sanitizedKey)) {
        return convertBblPerMmcfToCubicMeterPerCubicMeter(isMetricOnSearch);
      } else if (attributesInMcfPerFt.includes(sanitizedKey)) {
        return convertMcfPerFtToCubicMeterPerMeter(isMetricOnSearch);
      } else {
        return "";
      }
    },
    [isMetricOnSearch]
  );

  return { getAttributeUnit };
};

export default useAttributeUnit;
